import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, NonNullableFormBuilder } from '@angular/forms'
import { animate, group, style, transition, trigger } from '@angular/animations'
import { ResetForm } from '@ngxs/form-plugin'
import { Store } from '@ngxs/store'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'
import { finalize, takeUntil } from 'rxjs'

import { MembershipResponse } from '../memberships/interfaces/membership-response'
import { UserProfileComponent } from '../user-profile.component'
import { Popup } from '../../../../core/classes/global/popup'
import { TitleResponse } from '../../../../core/interfaces/user/title-response'
import { OrgService } from '../../../../core/api'
import { BaseComponent } from '../../../components/base/base.component'
import { DashboardState } from '../../../../module/dashboard/states/dashboard.state'
import { Organization } from '../../../../core/interfaces/organization/organization'

@Component({
  selector: 'app-edit-membership',
  templateUrl: 'edit-membership.component.html',
  styleUrl: 'edit-membership.component.scss',
  animations: [
    trigger('titleAnimation', [
      transition(':enter', [
        group([
          style({
            opacity: 0,
            transform: 'perspective(1000px) translateZ(-200px)',
            'z-index': 0,
            height: 0,
            marginBottom: 0
          }),
          animate(
            '0.4s cubic-bezier(0.4, 0.1, 0.3, 0.9)',
            style({
              opacity: 1,
              transform: 'perspective(1000px) translateZ(0)',
              'z-index': 0,
              height: '*',
              marginBottom: '*'
            })
          )
        ])
      ]),
      transition(':leave', [
        group([
          style({
            opacity: 1,
            transform: 'perspective(1000px) translateZ(0)',
            'z-index': 0,
            height: '*',
            marginBottom: '*'
          }),
          animate(
            '0.4s cubic-bezier(0.3, 0.1, 0.3, 1)',
            style({
              opacity: 0,
              transform: 'perspective(1000px) translateZ(-200px)',
              'z-index': 0,
              height: 0,
              marginBottom: 0
            })
          )
        ])
      ])
    ])
  ],
  standalone: false
})
export class EditMembershipComponent extends BaseComponent implements OnInit, OnDestroy {
  @SelectSnapshot(DashboardState.organization) organization: Organization

  @Input({ required: true }) membership: MembershipResponse
  @Input({ required: true }) hostComponent: UserProfileComponent

  membershipForm: FormGroup<{
    isPlayer: FormControl<boolean>
    isAdmin: FormControl<boolean>
    jerseyNumber: FormControl<number | null>
    titles: FormControl<{ id: number; title: string }[]>
    isExternal: FormControl<boolean>
  }>
  titlesPopup: Popup = new Popup()
  titlesForm: FormGroup<{
    search: FormControl<string>
    titles?: FormGroup<{ [id: number]: FormControl<boolean> }>
  }>
  availableTitles: TitleResponse[] = []

  readonly membershipFormNgxsFormPath: string = 'userProfile.membership'

  get filteredTitles(): TitleResponse[] {
    return this.availableTitles.filter((title) =>
      title.title.toLowerCase().includes(this.titlesForm.value.search!.toLowerCase())
    )
  }

  constructor(
    private formBuilder: NonNullableFormBuilder,
    private store: Store,
    private orgService: OrgService
  ) {
    super()
  }

  ngOnInit() {
    this.membershipForm = this.formBuilder.group({
      isPlayer: [this.membership.is_player],
      isAdmin: [this.membership.is_admin],
      jerseyNumber: [this.membership.jersey_number],
      titles: [this.membership.roles],
      isExternal: [this.membership.is_external]
    })

    this.titlesForm = this.formBuilder.group({
      search: ['']
    })
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetForm({ path: this.membershipFormNgxsFormPath }))
  }

  openTitlesPopup(): void {
    this.titlesPopup.open()

    if (this.availableTitles.length) {
      this.availableTitles.forEach((availableTitle) =>
        this.titlesForm.controls.titles!.controls[availableTitle.id].patchValue(
          !!this.membershipForm.value.titles!.find((title) => title.id === availableTitle.id)
        )
      )
    } else {
      this.titlesPopup.showLoader()
      this.orgService
        .getAdminTitles(this.membership.group.id)
        .pipe(
          finalize(() => this.titlesPopup.hideLoader()),
          takeUntil(this.componentDestroyed$)
        )
        .subscribe({
          next: (response) => {
            this.availableTitles = response
            const controls = {}
            this.availableTitles.forEach(
              (availableTitle) =>
                (controls[availableTitle.id] = [
                  !!this.membershipForm.value.titles!.find((title) => title.id === availableTitle.id)
                ])
            )
            this.titlesForm.addControl('titles', this.formBuilder.group(controls))
          },
          error: () => this.titlesPopup.close()
        })
    }
  }

  confirmTitles(): void {
    const updatedTitles: { id: number; title: string }[] = []
    Object.keys(this.titlesForm.value.titles!).forEach((titleId) => {
      if (this.titlesForm.value.titles![titleId]) {
        updatedTitles.push(this.availableTitles.find((title) => title.id === +titleId)!)
      }
    })
    this.membershipForm.patchValue({ titles: updatedTitles })
    this.titlesPopup.close()
  }

  removeTitle(id: number): void {
    this.membershipForm.patchValue({ titles: this.membershipForm.value.titles!.filter((title) => title.id !== id) })
  }

  resetSearch(): void {
    this.titlesForm.controls.search.reset()
  }
}
